import {Link, Route, Routes} from "react-router-dom";
import React from "react";
import {Outlet} from "react-router-dom";

const Layout = () => {
    return (
        <div className="App">
            <div className={"headerLinks"}>
                <div className={"headerLeft"}>
                    <Link to={"/"}>
                        <img className={"companyLogoWhite"} src={"/images/KamiStudiosWhite.png"} />
                    </Link>
                </div>
                <div className={"headerRight"}>
                    <Link to={"/press"}>Press kit</Link>
                </div>

            </div>
            <Outlet />
        </div>
    );
}

export default Layout;