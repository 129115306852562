import React from 'react';
import './Press.css';

function Press() {
    return (
        <div className={"pressContainer"}>
            <div className="twoColumns">
                <div className="colLeft">
                    <div className="section">
                        <h2>Factsheet</h2>
                        <h3>Developer</h3>
                        Kami Studios<br />
                        Based in Stockholm, Sweden

                        <h3>Founding date:</h3>
                        September 1st, 2020

                        <h3>Website:</h3>
                        <a href={"https://www.cliffandfield.com"}>https://www.cliffandfield.com</a>

                        <h3>Press / Business contact:</h3>
                        <a href={"mailto:h@kami.vc"}>h@kami.vc</a>

                        <h3>Social:</h3>
                        <a href={"https://discord.gg/G5MxaSpaM5"}>Discord</a><br/>
                        <a href={"https://www.instagram.com/cliffandfield/"}>Instagram</a><br/>
                        <a href={"https://www.reddit.com/r/cliffandfield/"}>Reddit</a><br/>
                        <a href={"https://www.twitter.com/cliffandfield/"}>Twitter</a><br/>
                        <a href={"https://www.facebook.com/cliffandfield"}>Facebook</a>

                        <h3>Releases:</h3>
                        <a href={"/press/cliffandfield"}>Cliff & Field</a>
                    </div>
                </div>
                <div className="colRight">
                    <div className="section">
                        <h2>Description</h2>
                        Kami Studios is a Swedish independent game studio made up of Maximillian North and Gideon Steel.
                    </div>

                    <div className="section">
                        <h2>History</h2>
                        <h3>Origin</h3>
                        Kami Studios was founded in 2020 after the two long time friends realised that building a game from scratch would be something both challenging and fun.
                        After having spent most nights and weekends programming and designing away after regular work hours they both realized that while it's fun, it's also a lot of work to get over the finish line.
                        Despite at times being very limited in the amount of time they were able to spend on this project they jointly decided that during the summer vacations of 2022 they would make a true effort and spend every waking hour to complete their first game, the tower defense game Cliff & Field.
                        After months of development the demo came out and was met by praise from the small community that had amassed during the development time and that alone gave such a boost that they pushed on and together got the project over the finish line.

                        <h3>Future</h3>
                        Kami Studios is now looking to expand the Cliff & Field world after releasing it's first title but how fast and by how much will of course be determined by how the game is received!
                    </div>

                    <div className="section">
                        <h2>Projects</h2>
                        <ul>
                            <li><a href={"/press/cliffandfield"}>Cliff & Field Tower Defense</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Videos</h2>
                    <a className="videoTitle" href={"https://youtu.be/FgL6FXawKa4"}>Cliff & Field Tower Defense - Chaos Trailer at YouTube</a>
                    <div className={"videoContainer"}>
                        <iframe className={"video"} src="https://www.youtube.com/embed/FgL6FXawKa4"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                    <a className="videoTitle" href={"https://youtu.be/bLELcDMo6zc"}>Cliff & Field Tower Defense - Story Trailer at YouTube</a>
                    <div className={"videoContainer"}>
                        <iframe className={"video"} src="https://www.youtube.com/embed/bLELcDMo6zc"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Images</h2>
                    No from Kami Studios yet, please check out any of our projects for project specific images.
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Logo & Icon</h2>
                    <div className={"infoPill"}>
                        <a href={"https://drive.google.com/file/d/1GZcZP0HgpTQ6JijrmC-JLwCwwr0oIwYQ/view?usp=share_link"}>
                            Download the logotype in a .zip here (29kb)
                        </a>
                    </div>
                    <div className={"logotypes"}>
                        <img className={"companyLogo"} src={"images/KamiStudiosBlack.png"} />
                        <img className={"companyLogo companyLogoBackground"} src={"images/KamiStudiosWhite.png"} />
                    </div>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Awards & Recognition</h2>
                    <ul>
                        <li>Nothing here yet! If you're the award giving type and want to give us one then don't forget to let us know so we can tell the world about it here!</li>
                    </ul>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Selected Articles</h2>
                    <ul>
                        <li>Nothing here yet! If you write about us let us know and we'll let the world know here!</li>
                    </ul>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Additional Links</h2>
                    <ul>
                        <li>No additional links yet</li>
                    </ul>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Team & Collaborators</h2>
                    <ul>
                        <li>Gideon Steel<br />Developer, Tech Lead</li>
                        <li>Maximillian North<br />Creative lead, 3D Artist</li>
                        <li>Daniel Tibbing<br />Voice Actor</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Press;