import React from 'react';
import './../Press.css';

function CliffAndField() {
    return (
        <div className={"pressContainer"}>
            <div className="twoColumns">
                <div className="colLeft">
                    <div className="section">
                        <h2>Factsheet</h2>
                        <h3>Developer</h3>
                        <a href={"https://www.cliffandfield.com/press"}>Kami Studios</a><br />
                        Based in Stockholm, Sweden

                        <h3>Release date:</h3>
                         Early access: November 2022

                        <h3>Platforms:</h3>
                        <a href={"https://www.cliffandfield.com/press"}>PC, Mac (MacOS)</a>

                        <h3>Website:</h3>
                        <a href={"https://www.cliffandfield.com"}>https://www.cliffandfield.com</a>

                        <h3>Regular price:</h3>
                        $6.99
                    </div>
                </div>
                <div className="colRight">
                    <div className="section">
                        <h2>Description</h2>
                        Cliff & Field is an epic tower defense adventure that takes you on a fast-paced, atmospheric and bullet-packed journey through the lands of Cirin as you try to defend your new kingdom from what seems to be an endless wave of monsters.
                    </div>

                    <div className="section">
                        <h2>Detailed description</h2>
                        <h3>A NEW WORLD TO DEFEND</h3>
                        After discovering a hidden portal outside your town you’re transported to the land of Cirin where you play as the God-like leader who’s foretold to bring peace to the world of humans. You’ll wield powerful towers and weapons that you’ll get to upgrade and develop as you defend yourself and your newfound kingdom from what seems like a sure demise. The force that brought you to these lands wants you to succeed and helps you reset time so that you can refine your tactics and always come out on top.

                        <h3>UNLEASH YOUR MOST POWERFUL TOWERS</h3>
                        This new world holds many secrets in its ingenious construction of towers. You’ll be able to upgrade 6 different towers with 15 different upgrades each, allowing for over 750+ tower combos and thousands of possible strategies to discover as you go.

                        <h3>BUILT FOR REPLAYABILITY</h3>
                        Every run is different in Cliff & Field with things like randomness, critical hit chance, drops, and an ever-evolving onslaught of enemy hordes that adapt to your playstyle. New surprises await each time you decide to defend your land and unlockable permanent upgrades will make even the harder levels manageable with your favorite strategy.

                        <h3>NOTHING IS IMPOSSIBLE</h3>
                        Permanent upgrades mean you don't have to be an expert player yourself to be able to experience all the story and the glory that awaits in defending your land. Although should you want to challenge yourself there are several challenges awaiting and the possibility to play with or without permanent upgrades applied.
                    </div>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Videos</h2>
                    <a className="videoTitle" href={"https://youtu.be/FgL6FXawKa4"}>Cliff & Field Tower Defense - Chaos Trailer at YouTube</a>
                    <div className={"videoContainer"}>
                        <iframe className={"video"} src="https://www.youtube.com/embed/FgL6FXawKa4"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                    <a className="videoTitle" href={"https://youtu.be/bLELcDMo6zc"}>Cliff & Field Tower Defense - Story Trailer at YouTube</a>
                    <div className={"videoContainer"}>
                        <iframe className={"video"} src="https://www.youtube.com/embed/bLELcDMo6zc"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Images</h2>
                    <div className={"infoPill"}>
                        <a href={"https://drive.google.com/file/d/1R7Im_kxyEYvxel-I4pi_r91-Rk2ZGm5K/view?usp=share_link"}>
                            Download all the images in a .zip here (3.6Mb)
                        </a>
                    </div>

                    <div className={"images"}>
                        <a href={"../images/screenshots/ss_6e3d3246f73a618085d0b03b484bd9b251afe4ac.1920x1080.jpg"}><img className={"screenshot"} src={"../images/screenshots/ss_6e3d3246f73a618085d0b03b484bd9b251afe4ac.1920x1080.jpg"} /></a>
                        <a href={"../images/screenshots/ss_58d44e070b7a3b0ed72fc5fedc2214f5d323ecda.1920x1080.jpg"}><img className={"screenshot"} src={"../images/screenshots/ss_58d44e070b7a3b0ed72fc5fedc2214f5d323ecda.1920x1080.jpg"} /></a>
                        <a href={"../images/screenshots/ss_77a1d5a989027b3778fb9823b0169f957c00e7dc.1920x1080.jpg"}><img className={"screenshot"} src={"../images/screenshots/ss_77a1d5a989027b3778fb9823b0169f957c00e7dc.1920x1080.jpg"} /></a>
                        <a href={"../images/screenshots/ss_83b594a6d5daa71014c711597c924ae4cf544c08.1920x1080.jpg"}><img className={"screenshot"} src={"../images/screenshots/ss_83b594a6d5daa71014c711597c924ae4cf544c08.1920x1080.jpg"} /></a>
                        <a href={"../images/screenshots/ss_b2e19d2e942280cdfe3969040642f7de6c551b70.1920x1080.jpg"}> <img className={"screenshot"} src={"../images/screenshots/ss_b2e19d2e942280cdfe3969040642f7de6c551b70.1920x1080.jpg"} /></a>
                        <a href={"../images/screenshots/ss_dc0ee700aae3de2b2c3cb4b8d53b0e0728d0247d.1920x1080.jpg"}><img className={"screenshot"} src={"../images/screenshots/ss_dc0ee700aae3de2b2c3cb4b8d53b0e0728d0247d.1920x1080.jpg"} /></a>
                        <a href={"../images/screenshots/ss_ec75a21045cf46261d4771f8f376c1b9ac7870b3.1920x1080.jpg"}><img className={"screenshot"} src={"../images/screenshots/ss_ec75a21045cf46261d4771f8f376c1b9ac7870b3.1920x1080.jpg"} /></a>
                    </div>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Request Press Copy</h2>
                    Please <a href={"mailto:h@kami.vc?subject=Press Copy Request - Cliff and Field"}>click here to email us directly</a>.
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Awards & Recognition</h2>
                    <ul>
                        <li>Nothing here yet! If you're the award giving type and want to give Cliff & Field one then don't forget to let us know so we can tell the world about it here!</li>
                    </ul>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Selected Articles</h2>
                    <ul>
                        <li>Nothing here yet! If you write about Cliff & Field let us know and we'll let the world know here!</li>
                    </ul>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Additional Links</h2>
                    <ul>
                        <li>No additional links yet</li>
                    </ul>
                </div>
            </div>
            <div>
                <hr />
                <div className="section">
                    <h2>Team & Collaborators</h2>
                    <ul>
                        <li>Gideon Steel<br />Developer, Tech Lead</li>
                        <li>Maximillian North<br />Creative lead, 3D Artist</li>
                        <li>Daniel Tibbing<br />Voice Actor</li>
                        <li>Rocío Bachmaier Gonzalez<br />Voice Actor</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CliffAndField;