import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Landing from "./Landing";
import Press from "./Press";
import CliffAndField from "./press/cliffandfield";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Landing />} />
                    <Route path="press">
                        <Route index element={<Press />} />
                        <Route path="cliffandfield" element={<CliffAndField />} />
                    </Route>

                </Route>
            </Routes>
        </BrowserRouter>
    )
}

root.render(<App />);
